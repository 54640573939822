import React from 'react';
import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import { graphql } from 'gatsby';

import { ColumnSection, Column } from '../../../components/column-section';

import RelatedNewsSection from '../../../components/related-news-section';
import BigParagraph from '../../../components/big-paragraph';
import ReactMarkdown from 'react-markdown';
import { Languages } from '../../../tools/languages';
import { PrimaryButton } from '../../../components/buttons';
import { FormattedMessage } from 'react-intl';

import getCallToAction from '../../../tools/get-call-to-action';

const DutchSolutionDetailPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;
  const solution = data.solution;
  const { seo } = solution;

  return (
    <>
      <SEO
        pathName={pathName}
        title={seo?.title ?? solution.title}
        description={seo?.description}
        lang={language}
      />
      <Navigation transparent />
      <main>
        <SimpleHero
          image={
            solution.image
              ? solution.image.childImageSharp.original.src
              : 'https://via.placeholder.com/640?text=Bavak'
          }
          alt={solution.title}
        >
          <h1>{solution.title}</h1>
          <p>{solution.description}</p>
          <div>
            <PrimaryButton to={`/contact?solution=${solution.title}`}>
              <FormattedMessage id="hero-contact-cta" />
            </PrimaryButton>
          </div>
        </SimpleHero>
        <ColumnSection>
          <RelatedNewsSection language={language} />
          <Column>
            <BigParagraph>{solution.introduction}</BigParagraph>
            <ReactMarkdown>{solution.content}</ReactMarkdown>
          </Column>
        </ColumnSection>
        {getCallToAction(solution.CTA, language)}
      </main>
    </>
  );
};

export const query = graphql`
  query DutchSolutionDetail($slug: String!) {
    solution: strapiSolutions(slug: { eq: $slug }) {
      slug
      seo: DutchSEO {
        title
        description
      }
      title: title_nl
      description: description_nl
      introduction: introduction_nl
      content: content_nl
      CTA {
        name
        title: title_nl
        description: description_nl
        downloads: downloads_nl {
          name
          url
        }
      }
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default DutchSolutionDetailPage;
